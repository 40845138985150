
import './OthersProfileAllView.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ImSpinner9 } from 'react-icons/im';

//Components
import OthersProfileCoverdesignerView from './OthersProfileCoverdesignerView';
import OthersProfileAuthorView from './OthersProfileAuthorView';
import OthersProfileEditorView from './OthersProfileEditorView';
import OthersProfilePublisherView from './OthersProfilePublisherView';
import OthersProfileTestreaderView from './OthersProfileTestreaderView';
import OthersProfileBookView from './OthersProfileBookView';


const OthersProfileAllView = ({baseUrl, baseUrl2}) => {


    const [getUserProfileType, setUserProfileType] = useState();
    const [getCurrentUserProfileName, setCurrentUserProfileName] = useState("");
    const [error, setError] = useState(null);

    const [editorState, setEditorState] = useState(true);
    const [authorState, setAuthorState] = useState(true);
    const [testReaderState, setTestReaderState] = useState(true);
    const [coverDesignerState, setCoverDesignerState] = useState(true);
    const [publisherState, setPublisherState] = useState(true);
    const [bookState, setBookState] = useState(true);
    
    const location = useLocation(); 

    useEffect(() => {
        try {
            const othProfileName = new URLSearchParams(location.search).get('othProfileName');
            const othProfileType = new URLSearchParams(location.search).get('othProfileType');
    
            if (othProfileName) {
                //console.log('othProfileName:', othProfileName);
                setCurrentUserProfileName(othProfileName);
            } else {
                //console.log('othProfileName not found in the URL');
            }
    
            if (othProfileType) {
                //console.log('othProfileType:', othProfileType);
                setUserProfileType(othProfileType);
            } else {
                //console.log('othProfileType not found in the URL');
            }
        } catch (error) {
            handleError('Error extracting parameters from URL:', error);
        }
    }, [location.search]);


useEffect(() => {
    setEditorState(true);
    setAuthorState(true);
    setTestReaderState(true);
    setCoverDesignerState(true);
    setPublisherState(true);
    setBookState(true);    
}, []);


      const handleError = (message, error) => {
        console.error(message, error);
        setError('An error occurred. Please try again.');
        };

        return (
            <div className="OthersProfileAllView mb-20">

              {error && <div style={{ color: 'red' }}>{error}</div>}
          
              <div className="container" >


              { authorState && editorState && testReaderState && coverDesignerState && publisherState && bookState &&  (
          <div className='blurPage z-20 fixed inset-0 h-screen w-screen overflow-hidden'>
            <div className='loadingBox white-glassmorphism-loadingBox text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30' style={{ minWidth: '250px', maxWidth: '350px', minHeight:'100px' }}>
                <div className="flex justify-content-center align-items-center w-full h-full">
                  <div className='mt-10 ml-40'>
                        <ImSpinner9
                            className="spinning-image"
                            size={50}
                            color="#a7b7c8"
                        />
                  </div>               
                </div>
            </div>
        </div>

         )}




              {getUserProfileType === "Author" &&  (<><div className='pt-3 text-3xl'><b>Autor</b></div>
                                                      <OthersProfileAuthorView   
                                                        getUserProfileType={getUserProfileType}
                                                        getCurrentUserProfileName={getCurrentUserProfileName}
                                                        authorState={authorState}
                                                        setAuthorState={setAuthorState}
                                                        baseUrl={baseUrl}
                                                        baseUrl2={baseUrl2}
                                                      />
                                                    </>
              )}


              {getUserProfileType === "Book" && (<><div className='pt-3 text-3xl'><b>Buch</b></div>
                                                  <OthersProfileBookView     
                                                    getUserProfileType={getUserProfileType}
                                                    getCurrentUserProfileName={getCurrentUserProfileName}
                                                    bookState={bookState} 
                                                    setBookState={setBookState}
                                                    baseUrl={baseUrl}
                                                    baseUrl2={baseUrl2}
                                                  />
                                                </>
                          )}

              {getUserProfileType === "Editor" && (<><div className='pt-3 text-3xl'><b>Lektor & Korrektor</b></div>
                                                  <OthersProfileEditorView     
                                                        getUserProfileType={getUserProfileType}
                                                        getCurrentUserProfileName={getCurrentUserProfileName}
                                                        editorState={editorState} 
                                                        setEditorState={setEditorState} 
                                                        baseUrl={baseUrl} 
                                                        baseUrl2={baseUrl2}                                                    
						   />
                                                   </> 
                          )}

              {getUserProfileType === "Testreader" &&  (<><div className='pt-3 text-3xl'><b>Testleser</b></div>
                                                      <OthersProfileTestreaderView    
                                                        getUserProfileType={getUserProfileType}
                                                        getCurrentUserProfileName={getCurrentUserProfileName}
                                                        testReaderState={testReaderState} 
                                                        setTestReaderState={setTestReaderState}
                                                        baseUrl={baseUrl}
                                                        baseUrl2={baseUrl2}
                                                      />
                                                    </>
                          )}

              {getUserProfileType === "Publisher" &&  (<><div className='pt-3 text-3xl'><b>Verlag</b></div>
                                                    <OthersProfilePublisherView    
                                                        getUserProfileType={getUserProfileType}
                                                        getCurrentUserProfileName={getCurrentUserProfileName}
                                                        publisherState={publisherState} 
                                                        setPublisherState={setPublisherState}
                                                        baseUrl={baseUrl}
                                                        baseUrl2={baseUrl2}
                                                      />
                                                    </>
                          )}

              {getUserProfileType === "CoverDesigner" &&  (<><div className='pt-3 text-3xl'><b>Coverdesigner</b></div>
                                                    <OthersProfileCoverdesignerView     
                                                        getUserProfileType={getUserProfileType}
                                                        getCurrentUserProfileName={getCurrentUserProfileName}
                                                        coverDesignerState={coverDesignerState} 
                                                        setCoverDesignerState={setCoverDesignerState}
                                                        baseUrl={baseUrl}
                                                        baseUrl2={baseUrl2}
                                                      />
                                                    </>
                           )}

                </div>              
          </div>  


      );
}

export default OthersProfileAllView; 
export { default as OthersProfileAllView } from './OthersProfileAllView';