



const LogoutRedirectCheck  = (navigate) => {

         ///////////////////////////////// Logout redirect //////////////////////////7

        const logoutRedirectText = "Please Log in"
        try{
        if(logoutRedirectText === localStorage.getItem('logintoken') && localStorage.getItem('logoutTrue') === 'true'){
             navigate("/");
           // console.log('Logout Redirect1')
            const userText = "USER"

            localStorage.setItem('profileName', logoutRedirectText );
            localStorage.setItem('currentProfileImg', logoutRedirectText);
            localStorage.setItem('userRole', userText);

        }else{
           // console.log('Logout Redirect not Same Text')
           // console.log(localStorage.getItem('logintoken'));
           // console.log(localStorage.getItem('logoutTrue'));
        }
        } catch(err){
        console.log(err);
        }
    }

///////////////////////////////// Logout redirect //////////////////////////

export default LogoutRedirectCheck; 
export { default as LogoutRedirectCheck } from './LogoutRedirectCheck.js';